import React from 'react'
import logo from '../assets/images/Learning_Paths_Logo.png';

const LearningPathsTools = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
    </header>
)

export default LearningPathsTools
