import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import HeaderOne from '../components/LearningPathsTools'
import HeaderTwo from '../components/HeaderTwo'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import LogoSection from '../components/LogoSection'


class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Learning Paths - Connect the dots" />
        <LogoSection />
        <div id="main">
          <section id="highlights" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Highlights</h2>
                </header>
                <p>
                  Highlights - This Highlighter is the first tool in our suite. Bookmarks don't work. Capture what is relevant to you on a page with our highlighter. <br /> Add notes and tags. Mark them public if you want to share it with your fiends.
                </p>
                <ul className="actions">
                  <li>
                    <a className="button" href="https://chrome.google.com/webstore/detail/highlights-highlighter-an/fiajhjomgpnlefcbdhfghnbhpillkklb">Download for Chrome</a>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic01} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>How to use?</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Highlight as you read.</h3>
                <p>
                  Don't loose your reading flow. Just select the text and Highlight. Your highlights will show up when you visit the page next time..
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-copy"></span>
                <h3>Add more context on the page</h3>
                <p>
                  Once you are done reading the article. You can just click on any highlight to add tags and notes to the highlight..
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Add more details from dashboard</h3>
                <p>
                  Say you are lazy like us. You can always go to your dashboard to add more tags and notes at once. Search and add relevant data at once.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://alpha.app.learningpaths.io/#/register" target="_blank" className="button">
                    Learn More
                  </a>
                </li>
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Who is using?</h2>
              <p>
                The extension has been used by students, teachers, researchers and knowledge workers for the last two years.

              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>50,000+</strong> Highlights
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>2,969</strong> Tags
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>16,764</strong> Links
              </li>
              {/* <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li> */}
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,477</strong> Unique Users
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://alpha.app.learningpaths.io/#/register" target="_blank" className="button">
                    Learn More
                  </a>
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Get Started</h2>
              <p>
                Download our <a href="https://chrome.google.com/webstore/detail/highlights-highlighter-an/fiajhjomgpnlefcbdhfghnbhpillkklb">chrome extension</a> and <a href="https://alpha.app.learningpaths.io/#/register" target="_blank">create an account</a> with any social media account.&nbsp;
                <br />
                &nbsp; Just select the relevant text on any of your favorite blogs and start highlighting. It is that easy.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://alpha.app.learningpaths.io/#/register" className="button special" target="_blank">
                    Get Started
                  </a>
                </li>
                <li>
                  <a href="https://alpha.app.learningpaths.io/#/register" target="_blank" className="button">
                    Learn More
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
